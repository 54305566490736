@import url("https://fonts.googleapis.com/css?family=Poppins");

html, body {
  background: url('https://example.com/caminhao-fundo.jpg') no-repeat center center fixed;
  background-size: cover;
  font-family: "Poppins", sans-serif;
  height: 100vh;
  margin: 0;
  padding: 0;

}




.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

#formContent {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.9);
  padding: 30px;
  width: 90%;
  max-width: 400px;
  position: relative;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  text-align: center;
  backdrop-filter: blur(10px);
}

#icon {
  width: 50%;
  margin-bottom: 20px;
}

input[type="text"], input[type="password"] {
  background-color: #fff;
  border: 1px solid #ddd;
  color: #333;
  padding: 15px;
  font-size: 16px;
  width: 85%;
  border-radius: 5px;
  margin: 10px;
}

input[type="submit"] {
  background-color: #1b893b;
  border: none;
  color: white;
  padding: 15px;
  font-size: 16px;
  width: 90%;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  transition: 0.3s;
}

input[type="submit"]:hover {
  background-color: #efc028;
}

.muiTableContainer {
  overflow-x: visible !important; /* Permite que a tabela ultrapasse os limites */
}

.muiTable {
  min-width: 1600px !important; /* Mantém a tabela maior que a tela */
  white-space: nowrap;
}